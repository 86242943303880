import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import LangHelper from '../../../helpers/LangHelper/LangHelper'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import PlFlag from '../../../assets/images/flags/pl.png'
import EnFlag from '../../../assets/images/flags/en.png'
import UaFlag from '../../../assets/images/flags/ua.png'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router'
import { useState } from 'react'

const ChangeLang = ({ outSide, noMargin, defaultLang }) => {
  const t = LocalStorageHelper.get('translation')
  const history = useHistory()
  const [actualLang, setActualLang] = useState(defaultLang || LocalStorageHelper.get('lang'))
  const handleClose = (callback, lang) => {
    if (callback) callback()
    setActualLang(lang)
    LangHelper.changeLang(lang, history)
  }
  const style = {
    navMobile: {
      marginLeft: '10px',
      fontSize: '18px',
    },
  }
  const handleFlag = () => {
    switch (actualLang) {
      case 'pl': return PlFlag
      case 'en': return EnFlag
      case 'ua': return UaFlag
    }
  }
  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <>
            {
              // eslint-disable-next-line multiline-ternary
              BreakpointsHelper.isMobile() || outSide ? (
                <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 20px' }}>
                  <span style={{ marginRight: '10px', flex: 1 }}>{t.globals.nav.langChange}:</span>
                  <Button sx={{ marginTop: !noMargin && '10px', marginBottom: !noMargin && '10px', flex: 1 }} variant="text" {...bindTrigger(popupState)}>
                    <img src={handleFlag()} alt="flag" style={{ height: '30px' }} />
                    <span style={style.navMobile}>{actualLang === 'pl' ? t.globals.components.ChangeLang.pl : actualLang === 'en' ? t.globals.components.ChangeLang.en : t.globals.components.ChangeLang.ua}</span>
                  </Button>
                </Box>
                ) : (
                  <Button sx={{ width: '100%', borderRadius: 0, marginTop: !noMargin && '10px', marginBottom: !noMargin && '10px' }} variant="text" {...bindTrigger(popupState)}>
                    <img src={handleFlag()} alt="flag" style={{ height: '30px' }} />
                  </Button>
                )
            }
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => handleClose(popupState.close, 'pl')}>{t.globals.components.ChangeLang.pl}</MenuItem>
              <MenuItem onClick={() => handleClose(popupState.close, 'en')}>{t.globals.components.ChangeLang.en}</MenuItem>
              <MenuItem onClick={() => handleClose(popupState.close, 'ua')}>{t.globals.components.ChangeLang.ua}</MenuItem>
            </Menu>
          </>
        )}
      </PopupState>
    </>
  )
}

export default ChangeLang
