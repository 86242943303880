export default {
  xxlMax: '4080',
  xxlMin: '2560',
  xlMax: '2560',
  xlMin: '1920',
  lgMax: '1920',
  lgMin: '1280',
  mdMax: '1280',
  mdMin: '960',
  smMax: '960',
  smMin: '600',
  xsMax: '600',
  xsMin: '0',
}
