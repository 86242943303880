import React from 'react'
import ButtonMaterial from '@mui/material/Button'

const Button = ({ icon, text, onClick, variant, color, children, sx, type, disabled, noMargin, className }) => {
  const style = {
    root: {
      textTransform: 'uppercase',
      fontWeight: 700,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: noMargin ? '0' : '20px',
    },
    icon: {
      marginRight: '10px',
      display: 'flex',
      color,
    },
    text: {
      display: 'flex',
    }
  }
  return (
		<ButtonMaterial
			className={className}
			sx={sx || style.root}
			variant={variant || 'contained'}
			type={type}
			disabled={disabled}
			onClick={(e) => onClick && onClick(e)}
		>
			{
				icon && (
					<div style={style.icon}>
						{icon}
					</div>
				)
			}
			{
				(text || children) && (
					<div style={style.text}>
						{text || children}
					</div>
				)
			}
		</ButtonMaterial>
  )
}

export default Button
