import TranslationsEnum from '../../enums/LangEnum/LangEnum'
import LocalStorageHelper from '../LocalStorageHelper/LocalStorageHelper'
import LangHelper from './LangHelper'

export default {
  setJSONLanguage: (lang) => {
    Object.keys(lang).map((el) => el === LocalStorageHelper.get('lang') && LocalStorageHelper.set('translation', lang[el]))
  },
  setDefaultLanguage: (history) => {
    const lang = TranslationsEnum.LANG
    const beforeLang = LocalStorageHelper.get('lang')
    if (!beforeLang) {
      if (!LocalStorageHelper.get('user')?.defaultLang) {
        const defaultLang = TranslationsEnum.DEFAULT_LANG
        LocalStorageHelper.set('lang', defaultLang)
      } else {
        const defaultLang = LocalStorageHelper.get('user')?.defaultLang
        LocalStorageHelper.set('lang', defaultLang)
      }
    }
    if (LocalStorageHelper.get('user')?.defaultLang) {
      LocalStorageHelper.set('lang', LocalStorageHelper.get('user')?.defaultLang)
      Object.keys(lang).map((el) => el === LocalStorageHelper.get('user')?.defaultLang && LocalStorageHelper.set('translation', lang[el]))
      history?.push(0)
    }
    LangHelper.setJSONLanguage(lang)
  },
  changeLang: (langChange, history) => {
    const lang = TranslationsEnum.LANG
    LocalStorageHelper.set('lang', langChange)
    LangHelper.setJSONLanguage(lang)
    history?.push(0)
  },
}
