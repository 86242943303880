import en from '../../translations/en'
import pl from '../../translations/pl'
import ua from '../../translations/ua'

export default {
  DEFAULT_LANG: 'pl',
  AVAILABLE_LANGUAGES: ['pl', 'en', 'ua'],
  LANG: {
    en,
    pl,
    ua,
  },
  LOCALE_COOKIE_NAME: 'locale',
}
