import React, { useState } from 'react'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import logo from '../../../assets/images/logo.png'
import logoVertical from '../../../assets/images/logo_vertical.png'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import ChangeLang from '../../organisms/ChangeLang/ChangeLang'
import { Grid, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import Button from '@mui/material/Button'

const NavigationPreview = ({ location }) => {
  const t = LocalStorageHelper.get('translation')
  const [open, setOpen] = useState(false)
  const style = {
    nav: {
      position: 'fixed',
      top: 0,
      right: 0,
      width: `${BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 100px)'}`,
      margin: '0 auto',
      height: '100px',
      background: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: BreakpointsHelper.isMobile() ? 15 : 50,
      paddingLeft: BreakpointsHelper.isMobile() ? 15 : 50,
      zIndex: 999,
      boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    },
    logo: {
      height: BreakpointsHelper.isMobile() ? '55px' : '100%',
    },
    logoOpen: {
      height: BreakpointsHelper.isMobile() ? '55px' : '100%',
      marginLeft: BreakpointsHelper.isMobile() ? '0' : '35px',
      position: 'relative',
      left: '-5px',
      bottom: '-3px',
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
    },
    burgerOpen: {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'relative',
      right: '-5px',
      bottom: '-2px',
      marginBottom: '30px',
    },
  }
  const openLink = (link) => {
    location.history.push(link)
  }
  return (
		<div style={style.nav} className="navigation">
      <img src={BreakpointsHelper.isMobile() ? logoVertical : logo} alt={'logo'} style={style.logo} />
      {
        BreakpointsHelper.isMobile()
        ? (
          <div style={style.burger} onClick={() => setOpen(!open)}>
            <MenuIcon style={style.menu}/>
          </div>
        )
        : (
          <div style={style.buttons}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <ChangeLang noMargin />
            </Box>
          </div>
        )
      }
      {
        open && (
          <Box
            sx={{
              position: 'absolute',
              zIndex: '999',
              background: 'white',
              top: '0',
              left: '0',
              height: '100vh',
              width: 'calc(100% - 40px)',
              display: 'flex',
              flexDirection: 'column',
              padding: '20px',
            }}
          >
            <Grid container>
              <Grid item xs={6}>
                <img src={BreakpointsHelper.isMobile() ? logoVertical : logo} alt={'logo'} style={style.logoOpen} />
              </Grid>
              <Grid item xs={6}>
                <div style={style.burgerOpen} onClick={() => setOpen(!open)}>
                  <MenuIcon style={style.menu}/>
                </div>
              </Grid>
            </Grid>
            <Tooltip title={t.globals.nav.langChange} placement="right">
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ChangeLang noMargin />
              </Box>
            </Tooltip>
          </Box>
        )
      }
		</div>
  )
}

export default NavigationPreview
