import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { Redirect, useHistory } from 'react-router'
import './assets/scss/App.scss'
import { PublicRoute } from './router/PublicRoute/PublicRoute'
import HomePage from './pages/HomePage/HomePage'
import PreviewLayout from './layouts/PreviewLayout/PreviewLayout'
import LangHelper from './helpers/LangHelper/LangHelper'

const App = () => {
  const history = useHistory()
  LangHelper.setDefaultLanguage(history)
  return (
    <Router basename={'/'}>
      <Switch>
        <PublicRoute exact path={'/'} component={() => <Redirect to={'/home'} />} layout={PreviewLayout} />
        <PublicRoute path={'/home'} component={HomePage} layout={PreviewLayout} />
      </Switch>
    </Router>
  )
}

export default App
