import Section from '../../components/atoms/Section/Section'
import Row from '../../components/atoms/Row/Row'
import website3 from '../../assets/images/website/website3.jpg'
import website4 from '../../assets/images/website/website4.jpg'
import radoscSeksu from '../../assets/images/radosc_seksu.jpg'
import googleImages from '../../assets/images/pcloud.png'
import forum from '../../assets/images/poster_forum_pion_qr.jpg'
import Button from '../../components/atoms/Forms/Button/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Helmet } from 'react-helmet'
import SliderTop from '../../components/molecules/Slider/SliderTop'
import SliderCenter from '../../components/molecules/Slider/SliderCenter'
import SliderBottom from '../../components/molecules/Slider/SliderBottom'
import Contacts from '../../components/organisms/Contacts/Contacts'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import { Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import sprawozdanieFinansowe from '../../assets/files/sprawdozdanieFinansowe.pdf'
import sprawozdanieFinansowe2023 from '../../assets/files/Sprawozdanie finansowe 2023.pdf'
import sprawozdanieMerytoryczne from '../../assets/files/sprawozdanieMerytoryczne.pdf'
import sprawozdanieMerytoryczne2023 from '../../assets/files/Sprawozdanie merytoryczne 2023.pdf'
import statut from '../../assets/files/statut.pdf'
import polityka from '../../assets/files/polityka.pdf'
import darowizny from '../../assets/files/darowizny.pdf'
import regulamin from '../../assets/files/regulamin.pdf'

const HomePage = (props) => {
  const t = LocalStorageHelper.get('translation')
  const [totalAmount, setTotalAmout] = useState(null)
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lifesurfers</title>
        <meta name="keywords" content="gry planszowe, Szczecin, planszówki Lifesurfers" />
        <meta
          name="description"
          content="Zarejestruj się i stwórz, dołącz do najbliższego wydarzenia, aby poczuć magię planszówek. Pamiętaj, nie liczy się Twój wiek czy doświadczenie w graniu."
        />
        <meta name="subject" content="Lifesurfers" />
        <meta name="copyright" content="Lifesurfers" />
        <meta name="language" content="PL" />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="designer" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="copyright" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="owner" content="Jan Szymański, kontakt@your-site.pl" />
        <meta name="url" content="https://aplikacja.lifesurfers.pl" />
        <meta name="identifier-URL" content="https://aplikacja.lifesurfers.pl" />
        <meta name="category" content="Gry planszowe" />
        <meta name="distribution" content="Global" />
        <meta name="rating" content="General" />
        <meta name="revisit-after" content="7 days" />
        <meta httpEquiv="Expires" content="0" />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Cache-Control" content="no-cache" />
        <meta name="og:title" content="Lifesurfers" />
        <meta name="og:type" content="website" />
        <meta name="og:url" content="https://aplikacja.lifesurfers.pl" />
        <meta name="og:site_name" content="Lifesurfers" />
        <meta
          name="og:description"
          content="Zarejestruj się i stwórz, dołącz do najbliższego wydarzenia, aby poczuć magię planszówek. Pamiętaj, nie liczy się Twój wiek czy doświadczenie w graniu."
        />
        <meta name="fb:page_id" content="lifesurfers" />
        <meta name="og:email" content="fundacja@lifesurfers.pl" />
        <meta name="og:phone_number" content="500377540" />
        <meta name="og:locality" content="Szczecin" />
        <meta name="og:region" content="Zachodniopomorskie" />
        <meta name="og:postal-code" content="71-600" />
        <meta name="og:country-name" content="Polska" />
        <meta property="og:type" content="website" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta content="yes" name="apple-touch-fullscreen" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="format-detection" content="telephone=no" />
      </Helmet>
      <SliderTop {...props} />
      <Row
        right={<Section image={website4} />}
        left={
          <Section
            title="Kilka słów, na temat tego co robimy?"
            object={
              <>
                <p>
                  Fundacja Life Surfers powstała z pasji do pomagania młodym ludziom w utrzymywaniu się na falach życia
                  i osiąganiu swoich celów. Nazwa naszej fundacji doskonale oddaje nasze dążenie do udzielania wsparcia,
                  byśmy wszyscy mogli płynąć przez życie i osiągać sukcesy. Opieramy się na wartościach
                  chrześcijańskich, które stanowią fundament naszych działań.
                </p>
                <p>
                  Mimo że każdy z nas ma swoją rodzinę, pracę i obowiązki, poświęcamy swój czas, pieniądze i
                  zaangażowanie, aby dawać nadzieję innym. Nasza fundacja została oficjalnie zarejestrowana w KRS 4
                  grudnia 2017 roku, co umożliwiło nam podejmowanie działań w sposób formalny.{' '}
                </p>
                <p>
                  W 2022 roku otrzymaliśmy status Organizacji Pożytku Publicznego, co pozwala naszym darczyńcom na
                  odliczenie przekazanych kwot od podatku oraz przekazanie 1,5% swojego rozliczenia rocznego.{' '}
                </p>
                <p>
                  Na stronie głównej naszej fundacji warto byłoby zaktualizować plan na nadchodzący rok, aby
                  odzwierciedlał nasze najnowsze cele i działania.
                </p>
                <p>
                  Skupiamy się na wspieraniu i angażowaniu młodych ludzi, dlatego oferujemy im bezpośrednie wsparcie w
                  ich codziennym życiu. Poprzez tworzenie i wspieranie wspólnot oraz grup młodzieżowych, staramy się
                  rozwijać ich kreatywność i samodzielność, aby w przyszłości mogli samodzielnie podejmować inicjatywy.
                  Naszym celem jest także rozbudzenie wrażliwości młodzieży na potrzeby innych oraz wspieranie działań
                  prospołecznych. Dlatego stawiamy na wolontariat jako najlepszą formę wychowania i rozwoju potencjału
                  młodych ludzi.
                </p>
                <p>
                  Regularnie organizujemy cotygodniowe spotkania, na które serdecznie zapraszamy. Jeśli jesteś
                  zainteresowany/a, napisz do nas, a przekażemy Ci szczegółowe informacje.
                </p>
              </>
            }
          />
        }
      />
      <SliderCenter {...props} />
      <Row
        right={
          <>
            <Section image={forum} />
          </>
        }
        left={
          <Section
            title="Forum Ogień z Nieba"
            object={
              <>
                <p>
                  To inicjatywa będąca odpowiedzą na pragnienie Jezusa zawarte w słowach „Przyszedłem rzucić ogień na
                  ziemię i jakże bardzo pragnę, żeby on już zapłonął” (Łk 12, 49).
                </p>
                <p>
                  Wezwani przez Boga słowami o ogniu, który On chce rozpalić na ziemi, gorąco pragniemy dać Mu
                  odpowiedź. Właśnie dlatego powołaliśmy do istnienia cykliczne wydarzenie pn. Forum Ogień z Nieba. Jest
                  to spotkanie, podczas którego chcemy wołać o wylanie Ducha Świętego i rozpalać naszą wiarę przez
                  uwielbienie, głoszenie konferencji i świadectw, słuchanie żywego słowa, Eucharystię i modlitwę.
                  Pragniemy, by nasza wiara była żywa, by nie odznaczała się bylejakością ani letniością, lecz by
                  płonęła ogniem Miłości, która umiłowała nas od końca i oddała za nas życie. Chcemy dzielić się tym
                  doświadczeniem z innymi, zwłaszcza z osobami, które jeszcze jej w życiu nie doświadczyły.
                </p>
                <p>
                  Forum jest całodniowym biletowanym wydarzeniem organizowanym przez naszą fundację przy wsparciu wielu
                  szczecińskich wspólnot i udziale znanych gości z kraju i zagranicy. W 2024 roku odbędzie się już
                  <b> VI edycja </b>Forum.
                  <br />
                  <br /> Więcej informacji na stronie:
                  <b>
                    <a href="https://www.forumognia.pl/" target="_blank" rel="noreferrer">
                      {' '}
                      https://www.forumognia.pl
                    </a>
                  </b>
                </p>
                <Button
                    sx={{ minWidth: BreakpointsHelper.isMobile() ? '100%' : '100px' }}
                    onClick={() =>
                      window.open(
                        'https://zapisy.ichtis.info/zapisy/',
                        '_blank'
                      )
                    }
                  >
                    Zapisy
                  </Button>
              </>
            }
          />
        }
      />
      <Row
        // background
        // revertMobile
        left={<Section class="top-20" image={radoscSeksu} />}
        right={
          <Section
            title="Radość Seksu - Weekend dla małżeństw"
            object={
              <>
                <p>
                  Zapraszamy małżeństwa w każdym wieku na rekolekcje podczas których odkrywać będziemy piękno życia
                  seksualnego jakie odkrywa sakrament małżeństwa. Zachęcamy byście dali sobie ten czas we dwoje. Jeżeli
                  to możliwe przyjedźcie bez dzeici. <br></br>
                  Rekolekcje poprowadzi o. Knotz - wieloletni duszpasterz małżeństw i rekolekcjonista, który nie boi się
                  dotykać trudnych tematów związanych z seksem.
                </p>

                <p>
                  <b>Poruszymy takie tematy jak: </b>
                  <br></br>• Czy Bóg jest obecny we współżyciu? A jak jest, co to znaczy?<br></br>• Dlaczego seks w
                  małżeństwie jest taki ważny?<br></br>• Jak godzimy wiedzę seksuologiczną z wiarą i moralnością?
                  <br></br>• Poruszymy moralne aspekty życia zgodnego z cyklem i tematykę antykoncepcji i in vitro.{' '}
                  <br></br>• Dotkniemy tematu grzechu i moralności seksualnej.
                </p>
                <p>
                  <b>Miejsce: </b>
                  <br></br>• Dom Rekolekcyjny ul. Nadmorska 7 Mrzeżyno
                </p>
                <p>
                <b>Kiedy: </b>
                  <br></br>• 20 luty 2025 18:00 - 23 luty 2025 15:00
                </p>
                <p>
                  <b>Koszty:</b>
                  <br></br>• osoba dorosła (od 12 roku) - 450 zł<br></br>• dziecko (3-12) - 345 zł<br></br>• dziecko do
                  3 roku(bez dodatkowego łóżka) - 90 zł<br></br>
                </p>
                <p>
                  <b>
                    <Button
                    sx={{ minWidth: BreakpointsHelper.isMobile() ? '100%' : '100px' }}
                    onClick={() =>
                      window.open(
                        'https://docs.google.com/forms/d/e/1FAIpQLSdadH3Vgtz8HsKbjgKTrJlLlK0bEQNZrpPM44KbC3VGPAVYLg/viewform',
                        '_blank'
                      )
                    }
                  >
                    Zapisy
                  </Button>
                    <br></br>
                  </b>
                </p>
              </>
            }
          />
        }
      />
      <SliderBottom {...props} />
      <Row
        revertMobile
        left={
          <>
            <Section
              title="Sprawdź co robimy dla Ciebie"
              object={
                <>
                  <p>
                    Oto przedstawienie naszej fundacji Life Surfers oraz naszych głównych działań, które podejmujemy,
                    aby pomagać młodym ludziom utrzymać się na falach życia i osiągnąć swoje cele:
                  </p>
                  <p>
                    <b>DLA MŁODYCH</b>
                  </p>
                  <ul>
                    <li>warsztaty</li>
                    <li>pogadanki</li>
                    <li>wyjazdy</li>
                    <li>koncerty</li>
                    <li>spływy</li>
                    <li>konferencje</li>
                    <li>wolontariat</li>
                    <li>klub młodzieżowy (w planie)</li>
                  </ul>
                  <p>
                    <b>DLA MAŁŻEŃSTW</b>
                  </p>
                  <ul>
                    <li>spotkania tematyczne</li>
                    <li>warsztaty</li>
                    <li>odpoczynek wakacyjny</li>
                    <li>poradnictwo</li>
                  </ul>
                  <p>
                    <b>DLA POTRZEBUJĄCYCH</b>
                  </p>
                  <ul>
                    <li>pomoc materialna</li>
                    <li>wsparcie finansowe</li>
                    <li>akcje wolontaryjne</li>
                    <li>zbiórki</li>
                  </ul>
                </>
              }
            />
          </>
        }
        right={<Section image={website3} />}
      />
      <Row
        right={
          <>
            <Section
              title="Zobacz nasze zdjęcia "
              object={
                <>
                  <p>
                    Zapraszamy do zapoznania się z naszą galerią zdjęć, która ukazuje niezwykłe chwile i działania
                    Fundacji Life Surfers. Przez te zdjęcia chcemy podzielić się z Wami naszą pasją, zaangażowaniem i
                    radością, jaką czerpiemy z pomagania młodym ludziom utrzymać się na falach życia.
                  </p>
                  <p>
                    Nasze zdjęcia przedstawiają różnorodne wydarzenia i aktywności, które odbywają się pod egidą
                    Fundacji. Możecie zobaczyć uśmiechnięte twarze młodzieży, biorącej udział w naszych cotygodniowych
                    spotkaniach. To tam, poprzez tworzenie i wspieranie wspólnoty oraz grup młodzieżowych, rozwijamy ich
                    kreatywność, samodzielność i dajemy im szansę na rozwój.
                  </p>
                  <p>
                    W naszej galerii znajdziecie także zdjęcia z różnorodnych inicjatyw prospołecznych, w których
                    młodzież angażuje się jako wolontariusze. Naszym celem jest uwrażliwienie na potrzeby innych ludzi i
                    wspieranie działań, które mają pozytywny wpływ na społeczność. Te zdjęcia ukazują młodych serferów
                    życia w akcji, której owocem są uśmiechy i wdzięczność osób, którym pomagamy.
                  </p>
                  <p>
                    Przez galerię chcemy pokazać, że nasza fundacja nie tylko istnieje formalnie, ale także działa na
                    realnych płaszczyznach, angażując się w życie młodych ludzi i społeczności. Nasza praca oparta na
                    wartościach chrześcijańskich, oddana z pasją i zaangażowaniem, jest kontynuacją naszej misji
                    pomagania i dawania nadziei.
                  </p>
                  <p>
                    Zapraszamy do oglądania naszej galerii zdjęć, aby poczuć atmosferę, która towarzyszy naszym
                    działaniom. Dziękujemy wszystkim, którzy wspierają nasze wysiłki i wierzą w potencjał młodych ludzi.
                    Jesteśmy dumni z naszych serferów życia i razem tworzymy piękne fale, na których możemy pływać przez
                    życie i osiągać nasze cele.
                  </p>
                  <Button
                    sx={{ minWidth: BreakpointsHelper.isMobile() ? '100%' : '100px' }}
                    onClick={() =>
                      window.open(
                        'https://e.pcloud.link/publink/show?code=kZ61BsZ5acItcUbXuhjPW6Fk9N4kRHOrbMV&fbclid=IwAR2oiwGpYjBWgIPogSRwIIBxo5QR4_yP_iEDadvgisIDcbAZ2I568Vmn0z0',
                        '_blank'
                      )
                    }
                  >
                    Zobacz naszą galerię zdjęć
                  </Button>
                </>
              }
            />
          </>
        }
        left={<Section image={googleImages} />}
      />
      <Row
        background
        revertMobile
        left={
          <Section
            title="Kontakt"
            object={
              <>
                <p>
                  <b>Adres:</b>
                  <br />
                  Fundacja Life Surfers
                  <br />
                  ul. Parkowa 1/3, 71-600 Szczecin
                  <br />
                  <b>NIP:</b> 8522638007
                  <br />
                  <b>REGON:</b> 368901643
                  <br />
                  <b>KRS:</b> 0000707010
                  <br />
                </p>
                <p>
                  <b>Numer konta bankowego:</b>
                  <br />
                  BNP Paribas 23 1600 1462 1744 8476 0000 0001
                  <br />
                </p>
                <p>
                  <b>Telefon:</b> +48 500 377 540
                  <br />
                  <b>Email:</b> fundacja@lifesurfers.pl
                  <br />
                </p>
                <Contacts homePage />
              </>
            }
          />
        }
        right={
          <Section
            object={
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2376.9254631298463!2d14.565688951627147!3d53.43404097989936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47aa09734598760f%3A0xc37058eab4a5c60a!2sParkowa%201%2C%2071-600%20Szczecin!5e0!3m2!1sen!2spl!4v1672480086456!5m2!1sen!2spl"
                width="100%"
                height="600"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            }
          />
        }
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
          justifyContent: 'center',
          padding: '60px 50px',
          width: 'calc(100% - 100px)',
          borderTop: '1px solid rgba(0, 0, 0, 0.2)',
          flexDirection: BreakpointsHelper.isMobile() && 'column'
        }}
      >
        <Grid container spacing={4}>
          <Grid item md={3} xs={12}>
            <Typography sx={{ fontSize: '18px', marginBottom: '20px' }}>Wesprzyj nas</Typography>
            <Box sx={{ maxWidth: '320px' }}>
              <form
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                action="https://api.your-site.pl/payu/"
                method="POST"
              >
                <input type="hidden" name="idPos" value="4289134" />
                <input type="hidden" name="oauthClientId" value="4289134" />
                <input type="hidden" name="signatureKey" value="6f7ba8beb5c57ac3675472e7e22913e9" />
                <input type="hidden" name="oauthClientSecret" value="bca0a3fd71763dea2256be4c2ab4bcb7" />
                <input type="hidden" name="name" value="Wsparcie finansowe Fundacji Life Surfers" />
                <input type="hidden" name="description" value="Wsparcie finansowe Fundacji Life Surfers" />
                <TextField
                  sx={{ marginBottom: '10px', width: '100%' }}
                  type="text"
                  name="totalAmount"
                  placeholder="Ile chcesz wesprzeć? (podaj w groszach)"
                  onChange={(e) => setTotalAmout(e.target.value)}
                  value={totalAmount}
                />
                <input type="hidden" name="unitPrice" value={totalAmount} />
                <input type="hidden" name="quantity" value="1" />
                <TextField
                  sx={{ marginBottom: '10px', width: '100%' }}
                  type="text"
                  name="firstName"
                  placeholder="Podaj imię"
                />
                <TextField
                  sx={{ marginBottom: '10px', width: '100%' }}
                  type="text"
                  name="lastName"
                  placeholder="Podaj nazwisko"
                />
                <TextField
                  sx={{ marginBottom: '10px', width: '100%' }}
                  type="text"
                  name="email"
                  placeholder="Podaj email"
                />
                <TextField
                  sx={{ marginBottom: '10px', width: '100%' }}
                  type="text"
                  name="phone"
                  placeholder="Podaj telefon"
                />
                <input type="hidden" name="buyerName" value="Life Surfers" />
                <input type="hidden" name="recipientEmail" value="fundacja@lifesurfers.pl" />
                <input type="hidden" name="recipientPhone" value="500377540" />
                <input type="hidden" name="street" value="ul. Parkowa 1/3" />
                <input type="hidden" name="postalBox" value="Szczecin" />
                <input type="hidden" name="postalCode" value="71-600" />
                <input type="hidden" name="city" value="Szczecin" />
                <input type="hidden" name="state" value="Zachodniopomorskie" />
                <input type="hidden" name="continueUrl" value="" />
                <input type="hidden" name="notifyUrl" value="" />
                <Button type="submit" variant="contained">
                  Przejdź do płatności
                </Button>
              </form>
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography sx={{ fontSize: '18px', marginBottom: '20px' }}>Regulaminy</Typography>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={polityka} target="_blank" rel="noreferrer">
                {t?.homePage?.footer.desc3}
              </a>
            </Typography>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={regulamin} target="_blank" rel="noreferrer">
                {t?.homePage?.footer.desc2}
              </a>
            </Typography>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={darowizny} target="_blank" rel="noreferrer">
                Regulamin darowizn
              </a>
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography sx={{ fontSize: '18px', marginBottom: '20px' }}>Dokumenty</Typography>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={statut} target="_blank" rel="noreferrer">
                Statut
              </a>
            </Typography>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={sprawozdanieMerytoryczne} target="_blank" rel="noreferrer">
                Sprawozdanie Merytoryczne 2022
              </a>
            </Typography>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={sprawozdanieMerytoryczne2023} target="_blank" rel="noreferrer">
                Sprawozdanie Merytoryczne 2023
              </a>
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={sprawozdanieFinansowe} target="_blank" rel="noreferrer">
                Sprawozdanie Finansowe 2022
              </a>
            </Typography>
            <Typography
              sx={{
                marginRight: '10px',
                cursor: 'pointer'
              }}
            >
              <a href={sprawozdanieFinansowe2023} target="_blank" rel="noreferrer">
                Sprawozdanie Finansowe 2023
              </a>
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography sx={{ marginTop: '20px' }}>
              @ {new Date().getFullYear()} wszelkie prawa zastrzeżone przez Lifesurfers. Strona stworzona przez{' '}
              <a href="https://your-site.pl" target="_blank" rel="noreferrer">
                Your site
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default HomePage
