import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from '@mui/icons-material'
import Box from '@mui/material/Box'

const Carousel = ({ data, slide }) => {
  const height = 100
  const style = {
    left: {
      position: 'absolute',
      left: 0,
      top: height,
      zIndex: 990,
      background: 'transparent',
      border: 'none',
    },
    right: {
      position: 'absolute',
      right: 0,
      top: height,
      zIndex: 990,
      background: 'transparent',
      border: 'none',
    }
  }
  return (
    <Box sx={{ position: 'relative' }}>
      <CarouselProvider
        naturalSlideWidth={height}
        naturalSlideHeight={height}
        totalSlides={data?.length}
      >
        <Slider>
          {
            data?.map((el, index) => (
              <Slide index={index} key={index}>
                {slide(el)}
              </Slide>
            ))
          }
        </Slider>
        <ButtonBack style={style.left}><ArrowBackIosOutlined /></ButtonBack>
        <ButtonNext style={style.right}><ArrowForwardIosOutlined /></ButtonNext>
      </CarouselProvider>
    </Box>
  )
}

export default Carousel
